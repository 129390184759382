export enum ACCOUNT_ID_TYPE {
  MARGIN = 'M',
  MARGIN_N = 'N',
  NORMAL = 'C',
  NORMAL_X = 'X'
}

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F'
}

export enum EKYC {
  SUCCESS = 'Success',
  MANUAL = 'ManualCheck'
}

export const STEP_EKYC = {
  ID_CHECK: '0',
  ID_INFO: '1',
  FACE_CHECK: '2',
  ADD_BANK: '3',
  CONTRACT: '4',
  SIGNATURE: '5',
  CREATE_PIN: '6',
  REVIEW: '7'
};

export enum RECOMMEND_TYPE {
  'Mua',
  'Bán',
  'Giữ'
}

export enum VALUE_CHANGE_STATUS {
  NO_CHANGE,
  INCREASE,
  DECREASE
}

export enum RES_HOLIDAY_TYPE {
  CEREMORNY = 'Ceremorny'
}

export enum RES_STATUS {
  SUCCESS = 'Success'
}

export enum COUNTDOWN_STATUS {
  START = 'Start',
  COUNTING = 'Counting',
  END = 'End'
}

export const MTA_ACCOUNT_DESCRIPTION_MARGIN =
  'Gia tăng sức mua tối đa qua việc sử dụng chứng khoán làm tài sản đảm bảo.';

export const MTA_ACCOUNT_DESCRIPTION_NORMAL =
  'Mua chứng khoán bằng số dư tiền mặt trong tài khoản.';

export const TIME_VALID_OTP = 180000; //milliseconds
export const TIME_RESEND_OTP = 120000; //milliseconds
